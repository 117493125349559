/* You can add global styles to this file, and also import other style files */

@use 'compass-mixins/lib/compass';
//@use 'compass-mixins/lib/compass/reset';
@use 'bootstrap/scss/bootstrap';


@use 'styles/mixins';
@use 'styles/colors';
@use 'styles/fonts';
@use 'styles/layout';
@use 'styles/submenu';
@use 'styles/buttons';
@use 'styles/arrows';
@use 'styles/icons';
@use 'styles/wp-pages';
@use 'styles/my-profile';
@use 'styles/questionnaire';
@use 'styles/draggable';
@use 'styles/admin';
@use 'styles/ie';


* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  /* font-size: 62.5%; */
  scroll-behavior: smooth;
}
html, body { height: 100%; }
body {  
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}
