@use "submenu";

.admin-menu {
  @include submenu.subheader();
  min-height: auto;
  border:1px solid #eee;
  flex-wrap: wrap;
  
  li {
    border-right:1px solid #eee;

    a {
      padding: 5px 10px;
    }
  }
}


.admin-page {
  margin: 10px;
  @media (min-width:768px){
    margin: 15px 20px 15px 20px;
  }
}

.admin-form {
  label {
    display:block;
    width:100%;
    font-weight:bold;
  }
  textarea {
    display: block;
    width: 100%;
  }
  input:not([type='checkbox'], [type='submit']), select { width: 100%; }
}
.item-order {
  max-width: 60px;
}

.admin-data-table {
  $table-cell-border-color:#eee;

  thead {
    th{
      font-weight:bold;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color:$table-cell-border-color;
    }
  }

  td, th {
    padding:5px 10px;
    border:1px solid $table-cell-border-color;
    border-collapse: collapse;
  }
}
.input-not-valid {
  color: red;
  font-weight: 600;
}