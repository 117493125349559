@use 'colors';
@use 'layout';

.questionnaire {
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: layout.$max-page-width;
  margin: auto;
  margin-bottom: 20px;

  h4 {
    font-weight: 500;
    padding-left: 10px;
    padding-bottom: 5px;
    vertical-align: middle;
  }

  &-introduction {
    margin: 8px 0 12px 8px;
    font-size: 115%;
    font-weight: 600;
  }
}

.question {
  margin-bottom: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 15px;

  &-intro {
    margin: 12px 0 39px 8px;
    font-weight: 400;
    font-size: 105%;
  }

  &-explanation {
    padding: 8px;
    padding-bottom: 30px;
    margin-bottom: 9px;
    border: 2px solid colors.$yellow-info;
    border-radius: 5px;
    display: block;

    &-button {
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 10px;
    }

    &-content {
      display: inline;
      &-link {
        float: right;
        margin-right: 8px;
      }
    }
  }

  &-text {
    margin-bottom: 5px;
    position: relative;
    font-weight: 500;
  }
  &-answer {
    textarea {
      width: 100%;
      height: 75px;
      background: #efefef;
      border: 1px solid #ddd;
    }
  }

  @media (min-width: 768px) {
    border-radius: 0px;
    /* box-shadow: none; */
  }
}

.question-choice {
  label {
    width: 100%;
    text-align: left;
    display: inline;
  }
  textarea {
    display: block;
    margin-right: -10px;
    width: 100%;
    height: 70px;
    background: #efefef;
    border: 1px solid #ddd;
  }
}

/* table questionnaires in the form of:

| Vraag      |  Ja, graag!  |  Liever niet |
--------------------------------------------
| Aardbeien? |      []      |      []      |
| Vierkant?  |      []      |      []      |
| Vriendin?  |      []      |      []      |
| etc.
*/
.questionnaire-table {
  vertical-align: top;
  font-size: 95%;
  thead {
    font-weight: 700;
  }
  tr:nth-child(odd) {
    background: #eee;
    border-radius: 20px;
  }
  .id {
    font-weight: 600;
    padding-left: 13px;
    border-radius: 20px 0 0 20px;
    margin-left: -20px;
  }
  .table-question {
    @extend .question;
    font-weight: 600;
    padding: 7px;
  }
}
.theorem {
  @extend .question;
  padding-right: 10px;
  font-weight: 800;
}
.thead {
  font-size: 90%;
  font-weight: 700;
  padding: 0 5px;
}

.hide {
  display: none;
}
