@use 'colors';
@use "layout";

#pop-intro {
  ul {
    list-style: disc;
  }

  li {
    margin: .3em 0 .3em 1.5em;
  }
}

.questionnaire-category {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  @media (min-width: 600px) {
    width: 294px;
    min-width: 294px;
    max-width: 294px;
  }

  .questionnaire-no-fases {
    padding: 10px 15px;
    display: inline-block;
    margin: 15px;
    width: calc(100% - 30px);
    border-radius: 5px;
    border: 1px solid colors.$button-blue;
    color: colors.$button-blue;

    button {
      margin-top: 8px;
      min-width: 100%;
    }
  }
}
@media (min-width: 600px) {
  #groups-and-categories {
    position: absolute;
    z-index: 1;
    min-width: 311px; // firefox shows a horizontal scrollbar if the size is not defined (might be the box-model

    &.sticky-fixed {
      position: fixed;
      top: 0;
      bottom: 0;
      overflow-y: auto;
      max-height: calc(100vh);
      margin-bottom: 149px; // add margin-bottom with value #footer.height() to prevent the menu overlapping the footer
    }
  }
}
.profile-content {
  width: 100vw;
  padding: 20px;
  @media (min-width: 600px) {
    margin-left: 302px;
    width: calc(100% - 302px);
    max-width: calc(#{layout.$max-page-width}*0.75);
  }
}
.profile-content-full {
  width: 100%;
  padding: 40px;
}
.questionnaire-category-button {
  cursor: pointer;
  border-top: solid 2px white;

  padding: 10px 15px;
  display: inline-block;
  margin: 0 0 -8px 0;
  width: 100%;
  // height: 50px;

  color: var(--black-33);
  @include colors.ifIe() {
    color: colors.$black-33;
  }
  &{
  font-weight: 500;
  background: var(--footer-background);}
  @include colors.ifIe() {
    background: colors.$footer-background;
  }
  &{
  text-align: left;
  overflow: hidden;}

  &.selected {
    background: var(--footer-background-darker);
    @include colors.ifIe() {
      background: colors.$footer-background-darker;
    }
    &{
    color: white;}
  }
}
.questionnaire-category-button:hover {
  text-decoration: underline;
}

.questionnaire-group {
  display: none;
  ul {
    li {
      display: block;
      color: black;
      padding: 7px 0 7px 20px;
      width: 100%;
      background: #eee;
      cursor: pointer;

      &.selected {
        background: white;
        cursor: default;
        i {
          float: left;
          margin-left: -20px;
          height: 40px;
        }
      }
    }
    li:hover {
      background: rgb(226, 223, 223);
      &.selected {
        background: white;
      }
    }
  }
}
.open {
  height: 100%;
  opacity: 1;
  display: block;
  background: #eee;
}

.attention {
  color: colors.$red-alert;
  font-weight: 500;
}

.overzichten-menu {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: white;
    font-size: 90%;
  }

  li {
    float: right;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }

  li a:hover {
    color: orange;
  }
}

.busy-saving {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000066;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: none;
  color: white;

  &-msg {
    text-align: center;
    width: 200px;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -60%);
  }
}
