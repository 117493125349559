@use "colors";

$button-border-radius: 5px;

@mixin button( $clr, $clr-hover, $border-radius: $button-border-radius ) {
  &{
  border: none;
  border-radius: $border-radius;
  background: $clr;
  font-weight: 500;
  vertical-align: bottom;
  color: white;
  font-size: 130%;
  max-width: 150px;
  padding: 5px 10px 6px 10px;
  cursor: pointer;}
  &:hover {
    background: $clr-hover !important;
  }
}

button {
  $main: var(--button-blue);
  $hover: var(--button-blue-hover);
  @include colors.ifIe() { $main: colors.$button-blue; $hover: colors.$button-blue-hover; }
  @include button( $main, $hover );

  /* &.accept {
    $main: var(--green-accept);
    $hover: var(--green2-lighter-10);
    @include ifIe() { $main: $green-accept; $hover: $green2-lighter-10; }
    @include button( $main, $hover );
  }
  &.cancel {
    $main: var(--green-accept);
    $hover: var(--green2-lighter-10);
    @include ifIe() { $main: $green-accept; $hover: $green2-lighter-10; }
    @include button( $main, $hover );

    @include button( var(--red-deny), var(--red-deny-lighter-10) );
  } */
}

.button {
  $main: var(--button-blue);
  $hover: var(--button-blue-hover);
  @include colors.ifIe() { $main: colors.$button-blue; $hover: colors.$button-blue-hover; }
  @include button( $main, $hover );
}

