
@use 'colors';

$header-height: 97px;
$header-height-mobile: 67px;
$subheader-height: 91px;
$total-header-height: 142px;
$max-page-width: 1180px;

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: var(--body-text);
  @include colors.ifIe(){ color : colors.$body-text; }
}
h1, h2, h3, h4, h5, h6 {
  color: var(--header-text);
  @include colors.ifIe(){ color : colors.$header-text; }
}
.background {
  // padding: 10px;
  width: 100%;
  background: var(--home-page-background);
  @include colors.ifIe(){ background : colors.$home-page-background; }
}
.background-submenu {
  background: var(--nietwerken-tab); /* Old browsers */
  background: -moz-linear-gradient(left, var(--onderwijs-tab) 0%, var(--onderwijs-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, var(--onderwijs-tab) 0%,var(--onderwijs-tab) 50%,var(--nietwerken-tab) 51%,var(--nietwerken-tab) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, var(--onderwijs-tab) 0%,var(--onderwijs-tab) 50%,var(--nietwerken-tab) 51%,var(--nietwerken-tab) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  @include colors.ifIe(){ 
    background: var(colors.$nietwerken-tab); /* Old browsers */
    background: -moz-linear-gradient(left, colors.$onderwijs-tab 0%, colors.$onderwijs-tab 50%, colors.$nietwerken-tab 51%, colors.$nietwerken-tab 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, colors.$onderwijs-tab 0%, colors.$onderwijs-tab 50%, colors.$nietwerken-tab 51%, colors.$nietwerken-tab 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, colors.$onderwijs-tab 0%, colors.$onderwijs-tab 50%, colors.$nietwerken-tab 51%, colors.$nietwerken-tab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}
.background-submenu-profile {
  background: var(--nietwerken-tab); /* Old browsers */
  background: -moz-linear-gradient(left, var(--werkhebben-tab) 0%, var(--werkhebben-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, var(--werkhebben-tab) 0%,var(--werkhebben-tab) 50%,var(--nietwerken-tab) 51%,var(--nietwerken-tab) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, var(--werkhebben-tab) 0%,var(--werkhebben-tab) 50%,var(--nietwerken-tab) 51%,var(--nietwerken-tab) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  @include colors.ifIe() {
    background: colors.$nietwerken-tab; /* Old browsers */
    background: -moz-linear-gradient(left, colors.$werkhebben-tab 0%, colors.$werkhebben-tab 50%, colors.$nietwerken-tab 51%, colors.$nietwerken-tab 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, colors.$werkhebben-tab 0%,colors.$werkhebben-tab 50%,colors.$nietwerken-tab 51%,colors.$nietwerken-tab 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, colors.$werkhebben-tab 0%,colors.$werkhebben-tab 50%,colors.$nietwerken-tab 51%,colors.$nietwerken-tab 100%);
  }
}

.container {
  max-width: $max-page-width;
  padding: 0;
}
.container > * img, .container > * iframe {
  filter: var(--grey-filter);
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.col {
  display: flex;
  flex-direction: column;
}
.hidden {
  display: none;
}

$page-margin-top: 20px;

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loadingSpinner:before {
  content: '';
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background: var(--loading-img) no-repeat;
  @include colors.ifIe(){ background: colors.$loading-img no-repeat; }
  &{
  background-size: contain;
  animation: spinner 1.75s linear infinite;
  margin: 0px auto;
  margin-top: 10px;}
}

@mixin  koptekst {
  color: var(--header-text);
  @include colors.ifIe(){ color: colors.$header-text; }
  &{font-weight: 500;}
}


// sticky footer
app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

app-home, app-free-page, app-wp-page, app-wp-pages,
app-wp-pages-by-search,
app-edit-profile, app-my-profile,
app-unauthorized, app-rapportages, 
app-add-comment, app-comments, app-coaches {
  flex: 1;
  max-width: $max-page-width;
  width: 100%;
  margin: 0px auto;
  // background: var(--home-page-background);
}

app-home, 
app-edit-profile, app-my-profile,
app-unauthorized, app-rapportages {
  background: #ffffff;
}
