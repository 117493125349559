
.module-icon {
  position: relative;
  top: 0px;
  width: 28px;
  height: 28px;
  display: inline-block;
}
.icon-question-list {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/question-list.svg');
  background-repeat: no-repeat;
}
.icon-email {
  @extend .module-icon;
  width: 50px;
  background-image: url('/assets/svg/module-icons/email.svg');
  background-repeat: no-repeat;
}
.icon-info {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/info.svg');
  background-repeat: no-repeat;
}
.icon-lockred {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/lock-red.svg');
  background-repeat: no-repeat;
}
.icon-lock {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/lock.svg');
  background-repeat: no-repeat;
}
.icon-search-coach {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/search-coach.svg');
  background-repeat: no-repeat;
}
.icon-search {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/search.svg');
  background-repeat: no-repeat;
}
.icon-writing {
  @extend .module-icon;
  background-image: url('/assets/svg/module-icons/writing.svg');
  background-repeat: no-repeat;
}