@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('/assets/fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
    url('/assets/fonts/montserrat/Montserrat-Light.woff') format('woff'),
    url('/assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('/assets/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('/assets/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('/assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('/assets/fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('/assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}
