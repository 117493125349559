@use "colors";
@use "layout";

@mixin wp-container {
  text-align: left;
  width: 100%;
}
.container-onderwijs {
  @include wp-container;
  /* background: var(--onderwijs-page);
  @include ifIe() { background: $onderwijs-page; } */
}
.container-uitgeschakeld {
  @include wp-container;
  /* background: var(--nietwerken-page);
  @include ifIe() { background: $nietwerken-page; } */
}
.container-werkzoeken {
  @include wp-container;
  /* background: var(--werkzoeken-page);
  @include ifIe() { background: $werkzoeken-page; } */
}
.container-werkhebben {
  @include wp-container;
  /* background: var(--werkhebben-page);
  @include ifIe() { background: $werkhebben-page; } */
}
.container-search {
  @include wp-container;
  /* background: var(--search-page);
  @include ifIe() { background: $search-page; } */
}
.wp-menu-items {
  width: 100%;
  display: inline-block;
  @media (min-width:768px) {
    max-width: 25%;
  }
}
.wp-menu-item {
  cursor: pointer;
  // background: white;
  font-size: 100%;
  padding: 10px;
  background: var(--footer-background);
  @include colors.ifIe() {
    background: colors.$footer-background;
  }
  &{
  margin: 2px 0 2px 0;
  // border-radius: 10px;
  // box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  // margin: 0; //15px 15px -4px 15px;
  min-height: 60px;}
  h5 {
    display: inline;
    /* color: var(--header-text);
    @include ifIe() { color: $header-text; } */
    color: var(--black-33);
    @include colors.ifIe() {
      color: colors.$black-33;
    }
    &{
    font-weight:600;
    margin-bottom: -2.3rem;}
  }
  .icons {
    float: right;
    width: 50%;
  }
  .content-preview {
    font-weight: 300;
    font-size: 80%;
  }
  .content-verder-link {
    float: right;
    // color: var(--orange-base);
    // @include ifIe() { color: $orange-base; }
  }
  /* @media (max-width: 768px) {
    margin: 15px 0px -4px 0px;
  } */
}
.wp-menu-item:hover {
  h4 {
    text-decoration: underline;
  }
}
.locked {
  opacity: .5;
}
.click-hint {
  font-weight: 600;
  color: green;
}
.wp-page-title {
  @include layout.koptekst();
  &{
  font-size: 150%;
  font-weight: 700;
  padding: 5px 10px;}
  @media (min-width:768px){
    font-size: 200%;
  }
}
.wp-page-bg {
  background: white;
  padding: 20px;
  // box-shadow: 0 1px 6px rgba(32, 33, 10, 0.1);
  width: 100%;
  display: inline-block;
  @media (min-width:768px) {
    width: 74%;
    margin: 0 5px 0 0;
  }
}

.wp-page-content {
  padding: 5px 10px;
  h1, h2, h3, h4, h5, h6 {
    color: var(--header-text);
    @include colors.ifIe() { color: colors.$header-text; }
    &{word-break: break-word;}
  }
  h1, h2, h3, h4 {
    border-top: var(--header-text) 1px solid;
    &:first-of-type {
      border-top: none;
    }
  }
  ul {list-style-type: circle;padding-left:30px;word-break: break-word;}
  ul.square {list-style-type: square;}
  ol { list-style-type: decimal;padding-left:30px;word-break: break-word; }
  ol.upper-alpha{list-style-type: upper-alpha;}
  ol.lower-alpha{list-style-type: lower-alpha;}
  ol.upper-roman {list-style-type: upper-roman;}
  ol.lower-roman {list-style-type: lower-roman;}

  tr, td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .striped tr:nth-child(even){background-color: #f2f2f2;}
  .hover tr:hover {background-color: #ddd;}
  th {
    font-weight: 500;
  }
  p {
    margin: 0;
  }
  em {
    font-style: italic;
  }
  
  .wp-image-100p {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}
.wp-col-2 {
  width: 100%;
  @media (min-width:768px){
    columns: 2;
  }
}
.wp-col-3 {
  width: 100%;
  @media (min-width:768px){
    columns: 2;
  }
  @media (min-width: 1100px) {
    columns: 3;
  }
}
.wp-line {
  height: 1px;
  border-bottom: 1px solid #aaa;
}
.wp-video {
  text-align: center;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}
.wp-video iframe,
.wp-video object,
.wp-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wp-block-image, .wp-page-content {
  .alignleft {
    float: left;
    padding-right: 5px;
  }
  .aligncenter {
    clear: both;
    display: block;
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .alignright {
    float: right;
    padding-left: 5px;
  }
}

/*
.wp-text-video {
  columns: 3;
  .wp-video {
    column-span: 2;
  }
}
*/

// fix FAQ layout */
app-free-page .background {
  padding:10px;

  .wp-page-bg {
    width:100%;
  }
}