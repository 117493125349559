@use "colors";
@use "layout";

$stripe-height: 10px;
$icons-height: calc( #{layout.$subheader-height} - #{$stripe-height} );

@mixin subheader() {
  max-height: layout.$subheader-height;
  min-height: layout.$subheader-height;
  width: 100%;
  max-width: layout.$max-page-width;
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
}
@mixin tab {
  max-height: layout.$subheader-height;
  min-height: layout.$subheader-height;
  margin: 0;
  width: 26%;
  transition: all .5s;
}
@mixin tab-icon {
  cursor: pointer;
  min-height: layout.$subheader-height;
  max-height: layout.$subheader-height;
  z-index: 10;
  margin-top: -91px;
}
@mixin tab-bg {
  width: auto;
  max-height: layout.$subheader-height;
  min-height: layout.$subheader-height;
  z-index: 5;
}
@mixin tab-title {
  text-align: center;
  width: auto;
  margin-top:  -(layout.$subheader-height);
  font-weight: 400;
  /* text-transform: uppercase; */
  font-size: 90%;
  padding-left: 8px;
  color: black;
  @media (max-width:660px) {
    display: none;
  }
}
.default-subheader {
  @include subheader();
  font-size: 130%;
  background: var(--header-text);
  @include colors.ifIe() { background: colors.$header-text; }
  &{
  color: white;
  font-weight: 600;
  line-height: layout.$subheader-height;
  padding-left: 20px;}
}
.scientific-subheader {
  @include subheader();
  font-size: 130%;
  background: var(--button-blue);
  @include colors.ifIe() { background: colors.$button-blue; }
  &{
  color: white;
  font-weight: 600;
  line-height: layout.$subheader-height;
  padding-left: 20px;}
  .icon {
    width: 100px;
    height: 100px;
    margin-top: -15px;
    color: white;
    background: url( '/assets/svg/wetenschappelijk_icon_white.svg' );
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.profile-subheader {
  @include subheader();
  font-size: 130%;
  color: white;
  font-weight: 600;
  line-height: layout.$subheader-height;
  padding-left: 20px;
}
.tab-selected {
  height: $stripe-height;
  background: var(--body-text);
  @include colors.ifIe() { background: colors.$body-text; }
  &{
    z-index: 15;
    margin-top: -$stripe-height;}
}

/* arrow selected class 
.tab-selected {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid black;
  z-index: 15;
}
*/